import { createI18n } from 'vue-i18n'
import sp from './locales/sp.json'
import en from './locales/en.json'
import pt from './locales/pt.json'

export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: "en",
  fallbackLocale: "en",
  messages: {
    'sp': sp,
    'en': en,
    'pt': pt
  }
});
