<template>
    <div>
        <div class="child">
            <label>
                page size
                <select @change="onPerPage">
                    <option>10</option>
                    <option>25</option>
                    <option>50</option>
                </select>
            </label>
        </div>
        <ul class="pagination">
            <li class="pagination-item">
                <button type="button" @click="onClickFirstPage" :disabled="isInFirstPage">
                    &lt;&lt;
                </button>
            </li>
            <li class="pagination-item">
                <button type="button" @click="onClickPreviousPage" :disabled="isInFirstPage">
                    &lt;
                </button>
            </li>
            <!-- Visible Buttons Start -->
            <li v-for="page in pages" :key="page.name" class="pagination-item">
                <button type="button" @click="onClickPage(page.name)" :disabled="page.isDisabled"
                    :class="{ active: isPageActive(page.name) }">
                    {{ page.name }}
                </button>
            </li>
            <!-- Visible Buttons End -->
            <li class="pagination-item">
                <button type="button" @click="onClickNextPage" :disabled="isInLastPage">
                    &gt;
                </button>
            </li>
            <li class="pagination-item">
                <button type="button" @click="onClickLastPage" :disabled="isInLastPage">
                    &gt;&gt;
                </button>
            </li>
        </ul>
    </div>
</template>
  
<script>
export default {
    emits: ['perPage', 'pagechanged'],
    data() {
        return {
            totalPages: 1,
            perPage: 10
        }
    },
    props: {
        maxVisibleButtons: {
            type: Number,
            required: false,
            default: 3
        },
        totalItems: {
            type: Number,
            required: true
        },
        currentPage: {
            type: Number,
            required: true
        }
    },
    watch: {
        totalItems() {
            // console.log("totalItems", this.totalItems);
            this.calcPages();
        }
    },
    computed: {
        startPage() {
            // When on the first page
            if (this.currentPage === 1) {
                return 1;
            }
            // When on the last page
            if (this.currentPage === this.totalPages) {
                const start = this.totalPages - (this.maxVisibleButtons - 1);
                if (start === 0) {
                    return 1;
                } else {
                    return start;
                }
            }
            // When inbetween
            return this.currentPage - 1;
        },
        pages() {
            const range = [];
            for (
                let i = this.startPage;
                i <= Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
                i++
            ) {
                range.push({
                    name: i,
                    isDisabled: i === this.currentPage
                });
            }
            return range;
        },
        isInFirstPage() {
            return this.currentPage === 1;
        },
        isInLastPage() {
            return this.currentPage === this.totalPages;
        },
    },
    methods: {
        calcPages() {
            var div = Math.trunc(this.totalItems / this.perPage);
            var rem = this.totalItems % this.perPage;
            this.totalPages = div;
            if (rem != 0) {
                this.totalPages++;
            }
            //    console.log("calcPages", this.totalPages);
        },
        onPerPage(event) {
            this.perPage = event.target.value;
            this.calcPages();
            this.$emit('perPage', this.perPage);
        },
        onClickFirstPage() {
            this.$emit('pagechanged', 1);
        },
        onClickPreviousPage() {
            this.$emit('pagechanged', this.currentPage - 1);
        },
        onClickPage(page) {
            this.$emit('pagechanged', page);
        },
        onClickNextPage() {
            this.$emit('pagechanged', this.currentPage + 1);
        },
        onClickLastPage() {
            this.$emit('pagechanged', this.totalPages);
        },
        isPageActive(page) {
            return this.currentPage === page;
        }
    }
};
</script>

<style scoped>
.pagination {
    list-style-type: none;
}

.pagination-item {
    display: inline-block;
}

.child {
    color: #aaa;
}

.active {
    background-color: green;
    color: #ffffff;
}
</style>