import { createStore } from 'vuex'
import axios from "axios";

export default createStore({
  state: {
    rootURL: "",
    authentication: false,
    tokenValue: "",
    userName: "",
    userRole: 0
  },
  getters: {
    rootURL(state) {
      return state.rootURL;
    },
    getToken(state) {
      return state.tokenValue;
    }
  },
  mutations: {
    setToken(state, payload) {
      state.tokenValue = payload.token;
    },
    setUserName(state, payload) {
      state.userName = payload.userName;
      state.userRole = payload.userRole;
    }
  },
  actions: {
    async HTTPGet(context, payload) {
      const url = context.state.rootURL + "/" + payload.url;
      const auth = "Bearer " + this.getters.getToken;

      console.log("INFO, HTTPGet, ", url);
      try {
        const response = await axios.get(url, {
          headers: {
            "Content-Type": payload.contentType,
            Authorization: auth,
          },
        });
        console.log("HTTPGet-Status", response.status);
        console.log("HTTPGet-Data", response.data);
        console.log("HTTPGet-Headers", response.headers);
        const strdata = String(response.data);
        if (strdata.includes("<html>")) {
          console.log("HTTPGet, HTML page");
          location.href = "/";
          location.reload();
        } else {
          return response.data;
        }
      } catch (error) {
        console.log("ERROR, HTTPGet, " + error);
        alert(error);
        return null;
      }
    },
    async HTTPPost(context, payload) {
      try {
        const url = context.state.rootURL + "/" + payload.url;
        const auth = "Bearer " + this.getters.getToken;
        //        console.log("INFO, HTTPPost", url);
        const response = await axios.post(url, JSON.stringify(payload.object), {
          headers: {
            Authorization: auth,
            "Content-Type": "application/json",
          },
        });
        console.log("HTTPPost-Status, ", response.status);
        console.log("HTTPPost-Data, ", response.data);
        return response.data;
      } catch (error) {
        console.log("ERROR, HTTPPost, " + error);
        return error;
      }
    },
    async HTTPPut(context, payload) {
      try {
        const url = context.state.rootURL + "/" + payload.url + payload.id;
        const auth = "Bearer " + this.getters.getToken;
        //        console.log("INFO, HTTPPut", url);
        const response = await axios.put(url, JSON.stringify(payload.object), {
          headers: {
            Authorization: auth,
            "Content-Type": "application/json",
          },
        });
        console.log("HTTPPut-Status, ", response.status);
        console.log("HTTPPut-Data, ", response.data);
        return response.data;
      } catch (error) {
        console.log("ERROR, HTTPPut, " + error);
        return error;
      }
    },
    async HTTPDelete(context, payload) {
      try {
        const url = context.state.rootURL + "/" + payload.url + payload.id;
        const auth = "Bearer " + this.getters.getToken;
        const response = await axios.delete(url, {
          headers: {
            Authorization: auth,
            "Content-Type": "application/json",
          },
        });
        console.log("HTTPDelete-Status, ", response.status);
        console.log("HTTPDelete-Data, ", response.data);
        return response.data;
      } catch (error) {
        console.log("ERROR, HTTPDelete, " + error);
        return error;
      }
    },
    async HTTPLogin(context) {
      const url = context.state.rootURL + "/validate_user";
      console.log("HTTPLogin", url);
      try {
        const response = await axios.get(url, {
          // Sacar en Produccion.   
/*          
                    headers: {
                      Authorization: "Basic " + btoa("admin:admin"),
                    },       
*/          
        });
        //        console.log("token: " + response.data.token);
        if (response.data.token != undefined) {
          const temp = JSON.stringify(response.data.token).split(".");
          const temp2 = JSON.parse(atob(temp[1]));
          console.log("HTTPLogin", temp2);
          this.commit("setUserName", { userName: temp2.UserName, userRole: temp2.UserRole });
          this.commit("setToken", { token: response.data.token });
        }
      } catch (error) {
        console.log("ERROR, HTTPLogin, " + error);
        alert(error);
        this.commit("setToken", { token: "" });
        location.href = "/";
        return null;
      }
    },
  },
  modules: {
  }
})
