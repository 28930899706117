<template>
        <nav-bar :title="$t('UserPage.UserManagement')"></nav-bar>
        <div class="col-12" style="background-color: #414e70;">
            <div class="panelep col-4" style="display:flex; align-items:center; justify-content:center;">
                <div class="labeltitle">
                    <div class="labeltitle" style="width:85%;">{{ $t("UserPage.SearchUser") }}</div>
                    <input type="text" style="width:85%;" placeholder="..." v-model="valueToSearch">
                </div>
                <div class="row" style="display:flex">
                    <button class="okbtn" @click="getUsers(valueToSearch)">{{ $t("UserPage.Query") }}</button>
                    <button class="cnlbtn" @click="getUsers('')">{{ $t("UserPage.Reset") }}</button>
                </div>
            </div>
        </div>
        <div class="row" v-if="results">
            <div>
                <table>
                    <tr>
                        <th>{{ $t("UserPage.User") }}</th>
                        <th>{{ $t("UserPage.Signature") }}</th>
                        <th>{{ $t("UserPage.Rights") }}</th>
                        <th>{{ $t("UserPage.TSyntax") }}</th>
                        <th>{{ $t("UserPage.Printer") }}</th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                    <tr v-for="item in results" :key="item.UserName">
                        <td>{{ item.UserName }}</td>
                        <td>{{ item.Signature }}</td>
                        <td>{{ getRole(item.Rights) }}</td>
                        <td>{{ getTransSyntax(item.TransSyntax) }}</td>
                        <td>{{ item.PrinterAET }}</td>
                        <td><button class="okbtn" @click="openEditDialog(item)" style="width: 60px;">
                                {{ $t("Common.Edit") }}</button></td>
                        <td><button class="okbtn" @click="openFilterDialog(item)" style="width: 60px;">
                                {{ $t("UserPage.Filter") }}</button>
                        </td>
                        <td><button class="cnlbtn" @click="sendDelete(item.UserName)" style="width: 60px;">
                                {{ $t("Common.Delete") }}</button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="separator2"></div>
            <div class="separator2"></div>
            <div class="separator"></div>
            <div class="separator"></div>
        </div>
        <div class="pagefoot col-12" style="background-color: #414e70;height:100px;">
            <button @click="openAddNewDialog" style="float:right;" class="okbtn">
                <i class="fas fa-arrow-circle-right"></i>{{ $t("Common.AddNew") }}</button>
        </div>
        <users-edit v-if="editModal" @close="closeModal" :selectedUser="selectedUser"></users-edit>
        <users-filter v-if="filterModal" @close="closeModal" :selectedUser="selectedUser"></users-filter>
        <footer-bar :title="$t('UserPage.UserManagement')"></footer-bar>
</template>
  
<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useStore } from 'vuex';

import UsersEdit from "../components/users/UsersEdit.vue";
import UsersFilter from "../components/users/UsersFilter.vue";

const store = useStore();

interface User {
    UserName: string;
    Signature: string;
    Rights: number;
    TransSyntax: number;
    PrinterAET: number;
}

var results = ref<User[]>([]);
var editModal = ref(false);
var filterModal = ref(false);
var selectedUser = ref(null);
var valueToSearch = "";

function closeModal() {
    selectedUser.value = null;
    editModal.value = false;
    filterModal.value = false;
    getUsers("");
}

function openAddNewDialog() {
    selectedUser.value = null;
    editModal.value = true;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function openEditDialog(user: any) {
    selectedUser.value = user;
    editModal.value = true;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function openFilterDialog(user: any) {
    selectedUser.value = user;
    filterModal.value = true;
}

async function sendDelete(id: string) {
    const result = await store.dispatch('HTTPDelete', { url: "users/", id: id });
    alert(result);
    getUsers("");
}

function getTransSyntax(index:number) {
    var list_TransSyntax = "";

    switch (index) {
        case 0: list_TransSyntax = "LE Explicit";
            break;
        case 1: list_TransSyntax = "JPEG Lossless";
            break;
        case 2: list_TransSyntax = "JPEG Lossy";
            break;
        case 3: list_TransSyntax = "JPEG 2K Lossless";
            break;
        case 4: list_TransSyntax = "JPEG 2K Lossy";
            break;
    }
    return list_TransSyntax;
}

function getRole(index: number) {
    var list_Role = "";

    switch (index) {
        case 0: list_Role = "General";
            break;
        case 1: list_Role = "Admin";
            break;
        case 2: list_Role = "Radiologist";
            break;
        case 3: list_Role = "Referring";
            break;
        case 4: list_Role = "Manager";
            break;
    }
    return list_Role;
}

async function getUsers(id: string) {
    var url = "users";

    if (id != undefined) {
        if (id.length != 0) {
            url = url + "/" + id;
        } else {
            valueToSearch = "";
        }
    } else {
        valueToSearch = "";
    }
    results.value = await store.dispatch("HTTPGet", { url: url });
    console.log("getUsers", results.value);
}

onMounted(async () => {
    getUsers("");
})
</script>
