<template>
    <nav class="navbar">
        <span class="open-slide">
            <a href="#" @click="openSlideMenu">
                <svg width="30" height="26">
                    <path d="M0,2 30,2" stroke="red" stroke-width="3" />
                    <path d="M0,9 30,9" stroke="red" stroke-width="3" />
                    <path d="M0,16 30,16" stroke="red" stroke-width="3" />
                    <path d="M0,23 30,23" stroke="red" stroke-width="3" />
                </svg>
            </a>
        </span>
        <div class="navbar-nav" style="color:white;padding:7px;font-size:22px;">{{ props.title }}</div>
        <ul class="navbar-nav">
            <li>
                <div id="txt"
                    style="color:white; text-align:right; font-size:19px; padding-right: 5px;margin-top: -36px;\">
                </div>
            </li>
            <li>
                <div id="date" style="color:white; text-align:right; font-size:14px; padding-right: 5px;\"></div>
            </li>
        </ul>
    </nav>
    <div id="side-menu" class="side-nav">
        <div class="menu">Menu</div>
        <div>
            <a href="#" class="btn-close" @click="closeSlideMenu">&times;</a>
        </div>
        <div>
            <router-link to="/" class="botonete">
                <i class="fas fa-home"></i>&nbsp;
                <b>{{ $t("NavBar.HomePage") }}</b>
                <br />{{ $t("NavBar.HomePageDes") }}
            </router-link>
        </div>
        <div>
            <router-link to="/users" class="botonete">
                <i class="fas fa-users-cog"></i>&nbsp;
                <b>{{ $t("NavBar.UserPage") }}</b>
                <br />{{ $t("NavBar.UserPageDes") }}
            </router-link>
        </div>
    </div>
    <div class="row">
        <div class="separator2"></div>
        <div class="separator2"></div>
        <div class="separator"></div>
        <div class="separator"></div>
    </div>
</template>

<script setup lang="ts">
import { defineProps, onMounted} from "vue";
import { today, now } from './common/routines';

const props = defineProps(['title']);

function openSlideMenu() {
    const sidemenu = document.getElementById('side-menu');
    if (sidemenu != null) {
        sidemenu.style.width = '310px';
    }
}

function closeSlideMenu() {
    const sidemenu = document.getElementById('side-menu');
    if (sidemenu != null) {
        sidemenu.style.width = '0';
    }
}

function startTime() {
    const tdate = document.getElementById('date');
    const ttime = document.getElementById('txt');
    if (ttime != null) {
        ttime.innerHTML = now();
    }
    if (tdate != null) {
        tdate.innerHTML = today();
    }
    setTimeout(startTime, 500);
}

onMounted(() => {
    console.log("NavBar");
    startTime();
})
</script>

<style scoped>
* {
    box-sizing: border-box;
    font-family: Lintel, Calibri, Helvetica, sans-serif;
    font-size: 12px;
    margin: 0;
}

body {
    background-color: #f9f9f9;
}

.open-slide {
    height: 45px;
}

.botonete {
    text-align: left;
}

.botonete:hover {
    background-color: red;
}

.navbar {
    background-color: gray;
    overflow: hidden;
    height: 50px;
}

.navbar a {
    float: left;
    display: block;
    color: blue;
    text-align: center;
    padding: 10px;
    text-decoration: none;
}

.navbar ul {
    list-style: none;
}

.navbar a:hover {
    color: #000;
}

.side-nav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    opacity: 0.9;
    overflow-x: hidden;
    padding-top: 60px;
    transition: 0.5s;
}

.side-nav a {
    padding: 10px 5px 5px 20px;
    text-decoration: none;
    font-size: 14px;
    color: #ccc;
    display: block;
    transition: 0.3s;
    z-index: 9999999999;
}

.side-nav a:hover {
    color: #fff;
    opacity: 1;
    transition: 0.5s;
}

.side-nav .menu {
    position: absolute;
    top: 10px;
    left: 5px;
    text-align: left;
    color: white;
    font-size: 34px;
}

.side-nav .btn-close {
    position: absolute;
    top: 0;
    right: 22px;
    font-size: 34px;
    margin-left: 50px;
}

.separator {
    width: 100%;
    height: 3px;
    background-color: #d3d3d3;
}

.separator2 {
    width: 100%;
    height: 3px;
    background-color: #e3e3e3;
}
</style>