<template>
    <modal-dialog @closeCancel="closeCancel" @closeOk="closeOk" :title="title"
    okBtn="Accept" cnlBtn="Cancel">
        <div class="labeltitle">
            <div class="labeltitle">{{ $t("Study.AccessionNumber") }}</div>
            <input type="text" v-model="wkl.id" :readonly="editMode">
        </div>
        <div class="labeltitle">
            <div class="labeltitle">{{ $t("Study.Comments") }}</div>
            <input type="text" v-model="wkl.clinicaldata">
        </div>
        <div class="labeltitle">
            <div class="labeltitle">{{ $t("Study.ID") }}</div>
            <input type="text" v-model="wkl.patid">
        </div>
        <div class="labeltitle">
            <div class="labeltitle">{{ $t("Study.Name") }}</div>
            <input type="text" v-model="wkl.patname">
        </div>
        <div class="labeltitle">
            <div class="labeltitle">{{ $t("Study.BD") }}</div>
            <input type="date" v-model="wkl.patbd">
        </div>
        <div class="labeltitle">
            <div class="labeltitle">{{ $t("Study.Sex") }}</div>
            <select v-model="wkl.patsex">
                <option value="M" selected> Male</option>
                <option value="F"> Female</option>
                <option value="O"> Other</option>
            </select>
        </div>
        <div class="labeltitle">
            <div class="labeltitle">{{ $t("Worklist.StartDate") }}</div>
            <input type="date" v-model="wkl.date">
        </div>
        <div class="labeltitle">
            <div class="labeltitle">{{ $t("Worklist.StartTime") }}</div>
            <input type="time" v-model="wkl.starttime">
        </div>
        <div class="labeltitle">
            <div class="labeltitle">{{ $t("Study.Modality") }}</div>
              <select v-model="wkl.modality">
                <option value="CR">CR</option>
                <option value="CT">CT</option>
                <option value="MR">MR</option>
                <option value="US">US</option>
                <option value="DX">DX</option>
                <option value="OT">OT</option>
                <option value="MG">MG</option>
                <option value="SR">SR</option>
                <option value="XA">XA</option>
                <option value="RF">RF</option>
                <option value="NM">NM</option>
                <option value="PT">PT</option>
                <option value="ECG">ECG</option>
                <option value="ES">ES</option>
              </select>
        </div>
        <div class="labeltitle">
            <div class="labeltitle">{{ $t("Study.Description") }}</div>
            <input type="text" v-model="wkl.descr">
        </div>
        <div class="labeltitle">
            <div class="labeltitle">{{ $t("Study.Status") }}</div>
            <select v-model="wkl.status">
                <option value="SCHEDULED"> Scheduled</option>
                <option value="INPROGRESS"> InProgress</option>
                <option value="DISCONTINUED"> Discontinued</option>
                <option value="COMPLETED"> Completed</option>
            </select>
        </div>
    </modal-dialog>
</template>

<script setup lang="ts">
import { defineEmits, defineProps, onMounted, ref } from "vue";
import { useStore } from 'vuex'
import { Worklist } from './Worklist';
import { today, now, DICOM2Date, DICOM2Time } from '../common/routines';

const emits = defineEmits(['close']);
const props = defineProps(['selectedEntries']);
const store = useStore();

var wkl = ref( new Worklist());
var title = ref("Edit Worklist Entry");
var editMode = ref(true);

function closeCancel() {
    emits('close');
}

async function closeOk() {
    if(wkl.value.id.length==0){
        alert("Add Worklist ERROR: Accession Number can't be empty");
        return;
    }
    if(editMode.value){
        const result = await store.dispatch('HTTPPut', { url: "worklist/", id: wkl.value.id, object: wkl.value });
        alert(result);
    } else {
        const result = await store.dispatch('HTTPPost', { url: "worklist/", object: wkl.value });
        alert(result);
    }
    emits('close');
}

onMounted(() => {
    if (props.selectedEntries.length>0) {
        if (props.selectedEntries.length > 1) {
            alert("ERROR, only one entry can be selected");
            emits('close');
        } else {
            wkl.value = props.selectedEntries[0];
            wkl.value.date = DICOM2Date(wkl.value.date);
            wkl.value.starttime = DICOM2Time(wkl.value.starttime);
        }
    } else {
        title.value = "Add New Worklist Entry";
        wkl.value.date = today();
        wkl.value.starttime = now();
        wkl.value.patbd = today();
        wkl.value.patsex="M";
        wkl.value.modality = "CR";
        wkl.value.status="SCHEDULED";
        editMode.value = false;
    }
});
</script>
