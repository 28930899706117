export class Worklist {
    id: string;
    descr: string;
    date: string;
    time: string;
    patid: string;
    patname: string;
    patbd: string;
    patsex: string;
    origin: string;
    modality: string;
    aet: string;
    referring: string;
    clinicaldata: string;
    studyinstanceuid: string;
    status: string;
    selected: boolean;

    constructor() {
        this.id="";
        this.descr="";
        this.date="";
        this.time="";
        this.patid="";
        this.patname="";
        this.patbd="";
        this.patsex="";
        this.origin="";
        this.modality="";
        this.aet="";
        this.referring="";
        this.clinicaldata="";
        this.studyinstanceuid="";
        this.status="";
        this.selected=false;
    }
}    
