<template>
	<div class="row footer" style="font-size:11;">
		<div class="col-7" style="margin-top:8px;">
			<div>{{ props.title }}</div>
		</div>
		<div class="fbutton" @click="logout">
			<i class="fas fa-power-off"></i> {{ $t("FooterBar.CloseSession") }}
		</div>
		<div class="fbutton" style="background-color: #374;">
			<a href="/changepass.html" class="botonete" style="color:white;">
			<i class="fa-solid fa-key"></i> {{ $t("FooterBar.ChangePassword") }}
			</a>
		</div>
		<div class="fbutton" style="background-color: #374;">
			<i class="far fa-user"></i> <strong>{{ $t("FooterBar.User") }}:</strong> {{ store.state.userName }}
		</div>
	</div>
</template>
<script setup lang="ts">
import { defineProps } from "vue";
import { useStore } from 'vuex';

const props = defineProps(['title', 'user']);
const store = useStore(); // for UserName

function logout() {
	window.location.href = store.state.rootURL + "/logout.html";
}
</script>

<style scoped>
.fbutton {
	height: 25px;
	width: 10%;
	background-color: red;
	border-radius: 3px;
	margin: 5px;
	padding-top: 8px;
	float: right;
	text-align: center;
}
.fbutton:hover {
	cursor: pointer;
	background: #ccc;
	color: #000;
}
.footer {
	display: inline-block;
	font-family: Lintel, Calibri, Helvetica, sans-serif;
	font-size: 12px;
	margin: 0;
	background-color: grey;
	color: white;
	text-align: left;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 9;
}

.logout {
	background-color: gray;
	color: white;
	width: 100%;
	padding: 5px;
	padding-top: 10px;
	text-align: center;
	z-index: 9;
	display: inline-block;
	cursor: pointer;
	height: 25px;
}

.logout:hover {
	background-color: red;
}
</style>