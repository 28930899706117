function pad(n:number) {
    return n<10 ? '0'+n : n;
}

export function yesterday(){
    const yesterday = (function(d){ d.setDate(d.getDate()-1); return d})(new Date)
    const dd = String(yesterday.getDate()).padStart(2, '0');
    const mm = String(yesterday.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = yesterday.getFullYear();

    return yyyy + "-" + mm + '-' + dd;
}

export function today(){
    const currentDate = new Date();
    const date = currentDate.getDate();
    const month = currentDate.getMonth(); 
    const year = currentDate.getFullYear();

    return year + "-" + pad(month + 1) + "-" +pad(date);
}

export function now(){
    const currentTime = new Date();
    const hour = currentTime.getHours();
    const min = currentTime.getMinutes();

    return pad(hour)+":"+pad(min);
}

export function DICOM2Date(inDate: string) {
    if(inDate!=undefined){
        if(inDate.length==8){
            const year = inDate.substring(0, 4);
            const month = inDate.substring(4, 6);
            const day = inDate.substring(6, 8);
            return year + '-' + month + '-' + day;
        }
    }
    return "";
}

export function Date2DICOM(inDate: string) {
    if(inDate.length==10){
        const year = inDate.substring(0, 4);
        const month = inDate.substring(5, 7);
        const day = inDate.substring(8, 10);
        return year + month + day;
    }
    return "";
}

export function DICOM2Time(inTime: string) {
    if(inTime!=undefined){
        let myTime = inTime;
        if(myTime.includes(".")){
            myTime = myTime.split(".")[0];
        }
        if((myTime.length==4)||(myTime.length==6)){
            const hour = myTime.substring(0, 2);
            const min = myTime.substring(2, 4);
            return hour + ':' + min;
        }
    }
    return "";
}

export function Time2DICOM(inTime: string) {
    if(inTime.length==5){
        const hour = inTime.substring(0, 2);
        const min = inTime.substring(2, 4);
        return hour + min;
    }
    return "";
}
