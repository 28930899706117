<template>
    <modal-dialog @closeCancel="closeCancel" @closeOk="closeOk" title="Delete Worklist Entry"
    okBtn="Delete" cnlBtn="Cancel" style="width:700px;">
        <div>
            <table>
                <tr>
                    <th>{{ $t("Study.AccessionNumber") }}</th>
                    <th>{{ $t("Study.ID") }}</th>
                    <th>{{ $t("Study.Name") }}</th>
                    <th>{{ $t("Study.Date") }}</th>
                    <th>{{ $t("Study.Modality") }}</th>
                    <th>{{ $t("Study.Description") }}</th>
                </tr>
                <tr v-for="item in props.selectedEntries" :key="item.id">
                    <td>{{ item.id }}</td>
                    <td>{{ item.patid }}</td>
                    <td>{{ item.patname }}</td>
                    <td>{{ DICOM2Date(item.date) }}</td>
                    <td>{{ item.modality }}</td>
                    <td>{{ item.descr }}</td>
                </tr>
            </table>
        </div>
    </modal-dialog>
</template>

<script setup lang="ts">
import { defineEmits, defineProps } from "vue";
import { useStore } from 'vuex';
import { DICOM2Date } from '../common/routines';

const emits = defineEmits(['close']);
const props = defineProps(['selectedEntries']);
const store = useStore();

function closeCancel() {
    emits('close');
}

async function closeOk() {
    // Delete selected Studies.
    for (let i = 0; i < props.selectedEntries.length; i++) {
        const params = {
            id: props.selectedEntries[i].id,
            patid: props.selectedEntries[i].patid,
            patname: props.selectedEntries[i].patname
        };
        await store.dispatch("HTTPDelete", { url: "worklist/", id: params.id });
//        alert(result);
    }
    // Poner mensaje si hubo error o todo andubo bien.
    emits('close');
}
</script>