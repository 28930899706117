<template>
    <modal-dialog @closeCancel="closeCancel" @closeOk="closeOk" title="User Filter"
    okBtn="Accept" cnlBtn="Cancel">
        <div class="labeltitle">
            <div class="labeltitle">{{ $t("UserEdit.UserName") }}</div>
            <input type="text" v-model="filter.username" style="width:85%;" readonly>
        </div>
        <div class="labeltitle">
            <div class="labeltitle">{{ $t("Study.ReferringPhysician") }}</div>
            <input type="text" v-model="filter.referring" style="width:85%;">
        </div>
        <div class="labeltitle">
            <div class="labeltitle">{{ $t("Study.Institution") }}</div>
            <input type="text" v-model="filter.institution" style="width:85%;">
        </div>
        <div class="labeltitle">
            <div class="labeltitle">{{ $t("Study.Description") }}</div>
            <input type="text" v-model="filter.description" style="width:85%;">
        </div>
        <div class="labeltitle">
            <div class="labeltitle">{{ $t("Study.Modality") }}</div>
            <input type="text" v-model="filter.modality" style="width:85%;">
        </div>
    </modal-dialog>
</template>
<script setup lang="ts">
import { defineEmits, defineProps, onMounted, ref } from "vue";
import { useStore } from 'vuex';

const emits = defineEmits(['close']);
const props = defineProps(['selectedUser']);
const store = useStore();

var filter = ref({
    username: "",
    referring: "",
    institution: "",
    description: "",
    modality: ""
});

function closeCancel() {
    emits('close');
}

async function closeOk() {
    console.log("closeOk", filter.value);
    const result = await store.dispatch('HTTPPut', {url: 'userfilter/', id: filter.value.username, object: filter.value });
    alert(result);
    emits('close');
}

onMounted(async () => {
    const results = await store.dispatch('HTTPGet', {url: 'userfilter/'+props.selectedUser.UserName });
    if(results.length>0){
        filter.value = results[0];
    } else {
        filter.value.username = props.selectedUser.UserName;
    }
})
</script>