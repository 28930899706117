import { createRouter, createWebHashHistory } from 'vue-router'
import WorklistPage from '../pages/WorklistPage.vue'
import UsersPage from '../pages/UsersPage.vue'

const routes = [
  {path: '/', name: 'home', component: WorklistPage},
  {path: '/users', name: 'users', component: UsersPage}
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
