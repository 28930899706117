<template>
    <modal-dialog @closeCancel="closeCancel" @closeOk="closeOk" :title="title" okBtn="Accept" cnlBtn="Cancel">
        <div class="labeltitle">
            <div class="labeltitle">{{ $t("UserEdit.UserName") }}</div>
            <input type="text" v-model="user.UserName" style="width:85%;" maxlength="25" :readonly="editMode">
        </div>
        <div class="labeltitle">
            <div class="labeltitle">{{ $t("UserEdit.PassWord") }}</div>
            <input type="text" v-model="user.PassWord" style="width:85%;" maxlength="25" required>
        </div>
        <div class="labeltitle">
            <div class="labeltitle">{{ $t("UserEdit.Rights") }}</div>
            <select style="width:85%;" v-model="user.Rights">
                <option value=0>{{ $t("UserEdit.GENERAL") }}</option>
                <option value=1>{{ $t("UserEdit.ADMIN") }}</option>
                <option value=2>{{ $t("UserEdit.RADIOLOGIST") }}</option>
                <option value=3>{{ $t("UserEdit.REFERRING") }}</option>
                <option value=4>{{ $t("UserEdit.MANAGER") }}</option>
            </select>
        </div>
        <div class="labeltitle">
            <div class="labeltitle">Transfer Syntax</div>
            <select style="width:85%;" v-model="user.TransSyntax">
                <option value=0> LE EXPLICIT</option>
                <option value=1> JPEG LOSSLESS</option>
                <option value=2> JPEG LOSSY</option>
                <option value=3> JPEG 2K LOSSLESS</option>
                <option value=4> JPEG 2K LOSSY</option>
            </select>
        </div>
        <div class="labeltitle" v-show="(user.Rights == 1) || (user.Rights == 2)">
            <div class="labeltitle">{{ $t("UserEdit.Signature") }}</div>
            <textarea v-model="user.Signature" style="width:85%; height:100%;" rows="3" cols="25"></textarea>
        </div>
        <div class="labeltitle" v-show="(user.Rights == 1) || (user.Rights == 2)">
            <img id='SignatureImage' :src="fileName" style="width:100%;">
            <label style="width:100%;height:75%;" for="Picture">
                <i class="fas fa-signature"></i>{{ $t("UserEdit.SignaturePicture") }}
            </label>
            <input type="file" accept="image/jpeg" @change="loadSignature">
        </div>
        <div class="labeltitle" v-show="(user.Rights == 1) || (user.Rights == 2)">
            <div class="labeltitle">
                <div class="labeltitle">{{ $t("UserEdit.DICOMPrinter") }}</div>
                <select v-model="user.PrinterAET">
                    <option v-for="(item, index) in printers" :key="index" :value="item.AET">
                        {{ item.AET }}
                    </option>
                </select>
            </div>
        </div>
    </modal-dialog>
</template>
<script setup lang="ts">
import { defineEmits, defineProps, onMounted, ref } from "vue";
import { useStore } from 'vuex';
import axios from "axios";

const emits = defineEmits(['close']);
const props = defineProps(['selectedUser']);
const store = useStore();

var user = ref({
    UserName: "",
    PassWord: "",
    Signature: "",
    Rights: 0,
    TransSyntax: 1,
    Image: "",
    PrinterAET: ""
});

interface printer {
    AET: string;
    IPAddress: string;
    PortNumber: string;
    gamma: number;
    contrast: number;
    destination: number;
    orientation: number;
    mediumType: number;
    filmSize: number;
}

var printers = ref<printer[]>([]);
var title = ref("Edit User");
var editMode = ref(true);
var filePicture: File;
var fileName = ref("");

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function loadSignature(event: any) {
    if (event.target !=null){
        filePicture = event.target.files[0];
        var reader = new FileReader();
        var imgtag = document.getElementById("SignatureImage") as HTMLImageElement;
        if (imgtag != null) {
            imgtag.title = filePicture.name;
            reader.onload = function (event) {
                if ((imgtag != null) && (event.target != null)) {
                    imgtag.src = event.target.result;
                }
            };
            reader.readAsDataURL(filePicture);
        }
    }
}

function closeCancel() {
    emits('close');
}

async function closeOk() {
    console.log("closeOk", user.value);
    if (user.value.UserName.length == 0) {
        alert("Add User ERROR: User Name can't be empty");
        return;
    }
    const formData = new FormData();
    formData.append("UserName", user.value.UserName);
    formData.append("PassWord", user.value.PassWord);
    formData.append("Signature", user.value.Signature);
    formData.append("Rights", String(user.value.Rights));
    formData.append("TransSyntax", String(user.value.TransSyntax));
    formData.append("PrinterAET", user.value.PrinterAET);
    if (filePicture!=null) {
        formData.append("File", filePicture);
    }
    const auth = "Bearer " + store.state.tokenValue;
    var url = store.state.rootURL;

    if (editMode.value) {
        url = url + "/userupdate";
    } else {
        if (user.value.PassWord.length == 0) {
            alert("Add User ERROR: PassWord can't be empty");
            return;
        }
        url = url + "/usernew";
    }
    const response = await axios.post(url, formData, {
        headers: {
            Authorization: auth,
            "Content-Type": "multipart/form-data",
        },
    });
    alert(response.data);
    emits('close');
}

onMounted(async () => {
    printers.value = await store.dispatch('HTTPGet', { url: 'dicomprinters' });
    if (props.selectedUser != null) {
        user.value = props.selectedUser;
        user.value.PassWord="";
        console.log("Selected Printer: ", user.value.PrinterAET);
        fileName.value = store.state.rootURL+"/templates/"+user.value.UserName+"/"+user.value.UserName+".jpg";
        console.log("UsersEdit", fileName.value);
    } else {
        title.value = "Add New User";
        editMode.value = false;
        if(printers.value.length>0){
            user.value.PrinterAET = printers.value[0].AET;
        }
    }
})
</script>
