<template>
<router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #237;
}
body {
	margin: 0 auto;
	padding: 0;
	background-color: lightgray;
	background-size: cover;
	background-position: center;
	font-family: sans-serif;    
}
.row {
    width: 100%;
    margin: 0 auto;
}

table {
    padding: 50px;
    border-collapse: collapse;
    width: 100%;
}

th,
td {
    text-align: center;
    padding: 4px;
    font-size: 12px;
    color: black;
}

tr:hover,
tr:nth-child(even):hover,
tr:nth-child(odd):hover {
    background-color:lightgray;
}

tr:nth-child(even) {
    background-color: #fff;
}

tr:nth-child(odd) {
    background-color: #eee;
}

th {
    background-color: gray;
    color: white;
}
.okbtn {
  background-color: #374;
  color: white;
  border: none;
  height: 30px;
  border-radius: 3px;
  margin: 5px;
  float: right;
  padding:5px;
  padding-top:8px;
  width:90px;
}
.okbtn:hover {
  cursor: pointer;
	background: #6a7;
	color: #000;
}
.cnlbtn {
  background-color: red;
  color: white;
  border: none;
  height: 30px;
  border-radius: 3px;
  margin: 5px;
  float: right;
  padding:5px;
  padding-top:8px;
  width: 90px;
}
.cnlbtn:hover {
  cursor: pointer;
	background: #f88;
	color: #000;
}
.labeltitle {
  width: 98%;
  background-color: grey;
  padding: 2px;
  margin-top: 5px;
  border-radius: 5px;
  color: white;
  font-size: 12px;
  font-weight: bold;
}
.labeltitle input {
  width: 98%;
  border-radius: 5px;
  border: none;
  padding: 2px;
  height: 20px;
  font-size: 12px;
}
.labeltitle select {
  width: 100%;
  border-radius: 5px;
  border: none;
  padding: 2px;
  height: 20px;
}
.row {
  width: 100%;
  margin: 0 auto;
}
.col-1 {width: 9%;float:left;}
.col-2 {width: 15%;float:left;}
.col-3 {width: 25%;float:left;}
.col-4 {width: 33%;float:left;}
.col-5 {width: 42%;float:left;}
.col-6 {width: 50%;float:left;}
.col-7 {width: 58%;float:left;}
.col-8 {width: 67%;float:left;}
.col-9 {width: 75%;float:left;}
.col-10 {width: 83%;float:left;}
.col-11 {width: 92%;float:left;}
.col-12 {width: 100%;float:left;}
.separator {
  width: 100%;
  height: 3px;
  background-color: #d3d3d3;
}
.separator2 {
  width: 100%;
  height: 3px;
  background-color: #e3e3e3;
}
.panelnp {
  background-color: #414e70;
  padding: 5px;
  margin-bottom: 5px;
}
.paneltitle {
  color: white;
  font-size: 14px;
  text-align: left;
}
</style>
