import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'

import NavBar from "./components/NavBar.vue";
import FooterBar from "./components/FooterBar.vue";
import ModalDialog from "./components/ModalDialog.vue";

const app = createApp(App)
// Registro NavBar, FooterBar como componentes globales.
// Asi es accesible desde todos los modulos.
app.component('nav-bar', NavBar);
app.component('footer-bar', FooterBar);
app.component('modal-dialog', ModalDialog);

app.use(router);
app.use(store);
app.use(i18n);

app.mount('#app')
