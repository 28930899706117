<template>
  <div class="row principal np">
    <div class="panelnp">
      <div class="row">
        <div class="paneltitle" style="padding:0px;">{{ $t("SearchForm.StudySearch") }}</div>
        <div class="separator"></div>
      </div>
      <div class="row" style="display:flex;align-items:center;">
        <div class="col-12">
          <div class="col-1">
            <div class="labeltitle">
              <div class="labeltitle">{{ $t("Study.AccessionNumber") }}</div>
              <input type="text" v-model="filter.accession" v-on:keyup.enter="submitQuery" />
            </div>
          </div>
          <div class="col-2">
            <div class="labeltitle">
              <div class="labeltitle">{{ $t("Study.ID") }}</div>
              <input type="text" v-model="filter.patid" v-on:keyup.enter="submitQuery" />
            </div>
          </div>
          <div class="col-2">
            <div class="labeltitle">
              <div class="labeltitle">{{ $t("Study.Name") }}</div>
              <input type="text" v-model="filter.patname" v-on:keyup.enter="submitQuery" />
            </div>
          </div>

          <div class="col-1">
            <div class="labeltitle">
              <div class="labeltitle">{{ $t("SearchForm.DateFrom") }}</div>
              <input type="date" v-model="filter.dateFrom" @change="copyDate" />
            </div>
          </div>
          <div class="col-1">
            <div class="labeltitle">
              <div class="labeltitle">{{ $t("SearchForm.DateTo") }}</div>
              <input type="date" v-model="filter.dateTo" />
            </div>
          </div>

          <div class="col-1">
            <div class="labeltitle">
              <div class="labeltitle">{{ $t("Study.Modality") }}</div>
              <select v-model="filter.modality">
                <option value="" selected>ALL</option>
                <option value="CR">CR</option>
                <option value="CT">CT</option>
                <option value="MR">MR</option>
                <option value="US">US</option>
                <option value="DX">DX</option>
                <option value="OT">OT</option>
                <option value="MG">MG</option>
                <option value="SR">SR</option>
                <option value="XA">XA</option>
                <option value="RF">RF</option>
                <option value="NM">NM</option>
                <option value="PT">PT</option>
                <option value="ECG">ECG</option>
                <option value="ES">ES</option>
              </select>
            </div>
          </div>
          <div class="col-1">
            <div class="labeltitle">
              <div class="labeltitle">{{ $t("Study.Description") }}</div>
              <input type="text" name="StudyDescription" v-model="filter.description" v-on:keyup.enter="submitQuery" />
            </div>
          </div>
          <div class="col-1" v-if="!worklist">
            <div class="labeltitle">
              <div class="labeltitle">{{ $t("Study.Institution") }}</div>
              <input type="text" name="InstitutionName" v-model="filter.institution" v-on:keyup.enter="submitQuery" />
            </div>
          </div>
          <div class="col-1">
            <div class="labeltitle">
              <div class="labeltitle">{{ $t("Study.Status") }}</div>
              <select v-model="filter.status" v-if="!worklist">
                <option value=0>ALL</option>
                <option value=1>Unread</option>
                <option value=2>Preliminary</option>
                <option value=3>Dictated</option>
                <option value=4>Transcribed</option>
                <option value=5>Final</option>
              </select>
              <select v-model="filter.status" v-if="worklist">
                <option value="">ALL</option>
                <option value="SCHEDULED">Scheduled</option>
                <option value="INPROGRESS">In Progress</option>
                <option value="DISCONTINUED">Discontinued</option>
                <option value="COMPLETED">Completed</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12" style="padding-top:5px;">
          <button class="cnlbtn" @click="resetQuery">
            <i class="fas fa-undo"></i>Reset
          </button>
          <button class="okbtn" @click="PDFlist">
            <i class="fas fa-clipboard-list"></i> PDF List
          </button>
          <button class="okbtn" @click="queryYesterday">
            <i class="far fa-arrow-alt-circle-left"></i>{{ $t("SearchForm.Yesterday") }}
          </button>
          <button class="okbtn" @click="queryToday">
            <i class="far fa-calendar-check"></i>{{ $t("SearchForm.Today") }}
          </button>
          <button class="okbtn" @click="submitQuery">
            <i class="fas fa-search"></i>{{ $t("SearchForm.Submit") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="separator2"></div>
    <div class="separator2"></div>
    <div class="separator"></div>
    <div class="separator"></div>
  </div>
</template>

<script setup lang="ts">
import { defineEmits, defineProps, onMounted, ref } from "vue";
import { today, yesterday } from './common/routines';
import { Date2DICOM } from './common/routines';

const emits = defineEmits(['query-data', 'pdf-list']);
const props = defineProps(['worklist']);

var filter = ref({
  accession: "",
  patid: "",
  patname: "",
  dateFrom: "",
  dateTo: "",
  modality: "",
  description: "",
  institution: "",
  status: 0
});

var query = ref("");

function createQuery() {
  var previous = false;

  query.value = "";
  if (filter.value.accession.length > 0) {
    query.value = query.value + "?accession=" + filter.value.accession;
    previous = true;
  }
  if (filter.value.patid.length > 0) {
    if (previous) {
      query.value = query.value + "&patid=" + filter.value.patid;
    } else {
      query.value = query.value + "?patid=" + filter.value.patid;
      previous = true;
    }
  }
  if (filter.value.patname.length > 0) {
    if (previous) {
      query.value = query.value + "&patname=" + filter.value.patname;
    } else {
      query.value = query.value + "?patname=" + filter.value.patname;
      previous = true;
    }
  }
  var querydate = "";

  if (filter.value.dateFrom.length > 0) {
    const frdate = Date2DICOM(filter.value.dateFrom);
    querydate = "date=" + frdate + "-";
  }
  if (filter.value.dateTo.length > 0) {
    const todate = Date2DICOM(filter.value.dateTo);
    querydate = "date=" + "-" + todate;
  }
  if ((filter.value.dateFrom.length > 0) && (filter.value.dateTo.length > 0)) {
    const fromdate = Date2DICOM(filter.value.dateFrom);
    const todate = Date2DICOM(filter.value.dateTo);
    console.log("fromdate=" + fromdate + ", todate=" + todate);
    querydate = "date=" + fromdate + "-" + todate;
  }
  if (querydate != "") {
    if (previous) {
      query.value = query.value + "&" + querydate;
    } else {
      query.value = query.value + "?" + querydate;
      previous = true;
    }
    console.log("createQuery: ", querydate);
  }
  if (filter.value.modality.length > 0) {
    if (previous) {
      query.value = query.value + "&modality=" + filter.value.modality;
    } else {
      query.value = query.value + "?modality=" + filter.value.modality;
      previous = true;
    }
  }
  if (filter.value.description.length > 0) {
    if (previous) {
      query.value = query.value + "&description=" + filter.value.description;
    } else {
      query.value = query.value + "?description=" + filter.value.description;
      previous = true;
    }
  }
  if (filter.value.institution.length > 0) {
    if (previous) {
        query.value = query.value + "&institution=" + filter.value.institution;
    } else {
      query.value = query.value + "?institution=" + filter.value.institution;
      previous = true;
    }
  }

  //      console.log("createQuery: ", filter.value.status);
  if ((props.worklist) && (filter.value.status != 0)) {
    status = filter.value.status;
    if (previous) {
      query.value = query.value + "&status=" + status;
    } else {
      query.value = query.value + "?status=" + status;
      previous = true;
    }
  } else if (filter.value.status > 0) {
    var status = filter.value.status - 1;
    if (previous) {
      query.value = query.value + "&status=" + status;
    } else {
      query.value = query.value + "?status=" + status;
      previous = true;
    }
  }
  if (previous) {
    query.value = query.value + "&limit=200"
  } else {
    query.value = query.value + "?limit=200"
  }
  saveFormDataState();
}

function copyDate() {
  console.log("copyDate");
  filter.value.dateTo = filter.value.dateFrom;
}

function submitQuery() {
  createQuery();
  console.log("submitQuery: ", query.value);
  emits('query-data', query.value);
}

function queryYesterday() {
  filter.value.dateFrom = yesterday();
  filter.value.dateTo = yesterday();
  createQuery();
  emits('query-data', query.value);
}

function queryToday() {
  filter.value.dateFrom = today();
  filter.value.dateTo = today();
  createQuery();
  emits('query-data', query.value);
}

function resetQuery() {
  filter.value = {
    accession: "",
    patid: "",
    patname: "",
    dateFrom: "",
    dateTo: "",
    modality: "",
    description: "",
    institution: "",
    status: 0,
  };
  createQuery();
  emits('query-data', query.value);
}

function PDFlist() {
  createQuery();
  emits('pdf-list', query.value);
}

function initFormDataState() {
  const storage = sessionStorage.getItem('formData');
  if (storage != null) {
    const formData = JSON.parse(storage || '');
    console.log("SearchForm, formData: ", formData);
    if (formData) {
      filter.value = formData;
    }
  }
}

function saveFormDataState() {
  const formData = JSON.stringify(filter.value);
  sessionStorage.setItem('formData', formData);
}

onMounted(() => {
  initFormDataState();
});

</script>

<style scoped>
* {
  box-sizing: border-box;
  font-size: 12px;
}

.np {
  background-color: #8592b2;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}
</style>