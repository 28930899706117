<template>
    <div id="edit-modal" class="modal">
        <div class="modal-content" :style="props.style">
            <div class="modal-header">
                <span class="closecancel" @click="closeCancel">&times;</span>
                <h2><i class="far fa-edit"></i> {{ props.title }}</h2>
            </div>
            <div class="modal-body">
                <slot></slot>
            </div>
            <div class="modal-footer">
                <div style="display:flex; justify-content:flex-end;">
                    <div v-if="!!props.okBtn">
                        <button style="float: none;" class="okbtn" @click="closeOk">
                            <i class="far fa-check-square"></i> {{ props.okBtn }}</button>
                    </div>
                    <div v-if="!!props.cnlBtn">
                        <button style="float: none;" class="cnlbtn" @click="closeCancel">
                            <i class="far fa-window-close"></i> {{ props.cnlBtn }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { defineEmits, defineProps } from "vue";

const emits = defineEmits(['closeCancel', 'closeOk']);
const props = defineProps(['title', 'okBtn', 'cnlBtn', 'style']);

function closeCancel() {
    emits('closeCancel');
}

async function closeOk() {
    emits('closeOk');
}
</script>
<style scoped>
.modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    overflow:auto;
}

.modal-content {
    margin: auto;
    margin-bottom: 30px;
    width: 350px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
    animation-name: modalopen;
    animation-duration: var(--modal-duration);
}

.modal-header h2,
.modal-footer h3 {
    margin: 0;
    font-size: 24px;
}

.modal-header {
    background-color: gray;
    padding: 10px;
    color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.modal-body {
    padding: 10px 20px;
    background: #D3D3D3;
}

.modal-footer {
    background-color: gray;
    padding: 10px;
    color: #fff;
    text-align: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.closecancel {
    color: #ccc;
    float: right;
    font-size: 30px;
    color: #fff;
}

.closecancel:hover,
.closecancel:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}
</style>
    