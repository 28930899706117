<template>
    <nav-bar title="Worklist"></nav-bar>
    <search-form @query-data="getWorklist" :worklist="true"></search-form>
    <div class="row" v-if="results">
      <div>
        <table>
          <tr>
            <th>Selection</th>
            <th>{{ $t("Study.AccessionNumber") }}</th>
            <th>{{ $t("Study.ID") }}</th>
            <th>{{ $t("Study.Name") }}</th>
            <th>{{ $t("Worklist.StartDateTime") }}</th>
            <th>{{ $t("Study.Modality") }}</th>
            <th>{{ $t("Study.Description") }}</th>
            <th>Status</th>
          </tr>
          <tr v-for="(item, index) in Slice()" :key="item.id">
            <td><input type="checkbox" v-model="selectedEntries" :value="item">
              {{ index + (currentPage - 1) * perPage + 1 }})
            </td>
            <td>{{ item.id }}</td>
            <td>{{ item.patid }}</td>
            <td>{{ item.patname }}</td>
            <td>{{ DICOM2Date(item.date) }} - {{ DICOM2Time(item.time) }}</td>
            <td> {{ item.modality }}</td>
            <td>{{ item.descr }}</td>
            <td>{{ item.status }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="separator2"></div>
      <div class="separator2"></div>
      <div class="separator"></div>
      <div class="separator"></div>
    </div>
    <div class="pagefoot col-12" style="height:100px; background-color: #414e70;">
      <button @click="openDeleteDialog" class="cnlbtn">
        <i class="fas fa-trash-alt"></i>{{ $t("Common.Delete") }}</button>
      <button @click="openAddNewDialog" class="okbtn">
        <i class="fas fa-arrow-circle-right"></i>{{ $t("Common.AddNew") }}</button>
      <button @click="openEditDialog" class="okbtn">
        <i class="far fa-edit"></i>{{ $t("Common.Edit") }}</button>
      <pagination-comp :totalItems="totalItems" :currentPage="currentPage" @perPage="onPerPage"
        @pagechanged="onPageChange"></pagination-comp>
    </div>
    <worklist-edit v-if="editModal" @close="closeModal" :selectedEntries="selectedEntries"></worklist-edit>
    <worklist-delete v-if="deleteModal" @close="closeModal" :selectedEntries="selectedEntries"></worklist-delete>
    <footer-bar title="Worklist"></footer-bar>
</template>
  
<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useStore } from 'vuex';

import SearchForm from "../components/SearchForm.vue";
import PaginationComp from '../components/PaginationComp.vue'
import WorklistEdit from "../components/worklist/WorklistEdit.vue";
import WorklistDelete from "../components/worklist/WorklistDelete.vue";
import { Worklist } from '../components/worklist/Worklist';
import { DICOM2Date, DICOM2Time } from "../components/common/routines";

const store = useStore();

var results = ref<Worklist[]>([]);
var selectedEntries = ref<Worklist[]>([]);
var editModal = ref(false);
var deleteModal = ref(false);
var admin = ref(false);
var manager = ref(false);
var currentPage = ref(1);
var totalItems = ref(0);
var perPage = ref(10);

function closeModal() {
  //  console.log("Worklist: closeModal");
  selectedEntries.value = [];
  editModal.value = false;
  deleteModal.value = false;
  getWorklist("");
}

function onPerPage(pPage: number) {
  perPage.value = pPage;
  currentPage.value = 1;
}

function onPageChange(page: number) {
  currentPage.value = page;
}

function openEditDialog() {
  if (selectedEntries.value.length > 0) {
    editModal.value = true;
  } else {
    alert("ERROR, No selected Entries!");
  }
}

function openAddNewDialog() {
  selectedEntries.value = [];
  editModal.value = true;
}

function openDeleteDialog() {
  // console.log("Worklist", this.selectedEntries);
  if (selectedEntries.value.length > 0) {
    deleteModal.value = true;
  } else {
    alert("ERROR, No selected studies!");
  }
}

async function getWorklist(query: string) {
  var url = "worklist" + query;

  const data = await store.dispatch('HTTPGet', { url: url });
  if (data != null) {
    results.value = data;
    totalItems.value = results.value.length;
  } else {
    totalItems.value = 0;
  }
}

function Slice() {
  let from = (currentPage.value - 1) * perPage.value;
  let to = (currentPage.value) * perPage.value;
  return results.value.slice(from, to);
}

onMounted(async () => {
  if(store.state.authentication){
    await store.dispatch("HTTPLogin");

    if (store.state.tokenValue.length > 0) {
      getWorklist("");
      admin.value = (store.state.userRole == 1);
      manager.value = (store.state.userRole == 4);
    } else {
      location.href = "/";
      //    location.reload();
    }
  } else {
    getWorklist("");
  }
})
</script>

<style scoped>
input[type="file"] {
  display: none;
  height: 0px;
}

label {
  background-color: #374;
  color: white;
  border: none;
  height: 22px;
  border-radius: 3px;
  float: right;
  margin: 5px;
  padding-top: 8px;
  width: 90px;
  font-size: 14px;
}
label:hover {
  cursor: pointer;
	background: #6a7;
	color: #000;
}</style>
  